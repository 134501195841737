<template>
  <div>
<div id="everything">
    <div id="header">
    <div class="left"><h1><router-link to="/repository/PubFig">PubFig: Public Figures Face Database</router-link></h1></div><br>
    <div class="right">
    <h2><router-link to="/repository/PubFig/explore" class="unselected">Explore</router-link></h2>
    <h2><router-link to="/repository/PubFig/download" class="unselected">Download</router-link></h2>
    <h2><router-link to="/repository/PubFig/results" class="unselected">Results</router-link></h2>
    </div>
</div>
<br>
<div id="download">
    <div id="topmatter">
        <h2>Overview</h2>
        The PubFig dataset is divided into 2 parts:
        <ol>
            <li>The Development Set contains images of <b>60 individuals</b>. This dataset should be used when developing your algorithm, so as to avoid overfitting on the evaluation set. There is NO overlap between this list and evaluation set, nor between this set and the people in the <a href="http://vis-www.cs.umass.edu/lfw/">LFW</a> dataset.</li>
            <li>The Evaluation Set contains images of the remaining <b>140 individuals.</b> This is the dataset on which you can evaluate your algorithm to see how it performs.</li>
        </ol>
        Due to copyright issues, we cannot distribute image files in any format to anyone. Instead, we have made available a list of image URLs where you can download the images yourself. We realize that this makes it impossible to exactly compare numbers, as image links will slowly disappear over time, but we have no other option. This seems to be the way other large web-based databases seem to be evolving. We hope to periodically update the dataset, removing broken links and adding new ones, allowing for close-to-exact comparisons.
        <h2 id="format">Data Format</h2>
        Almost all datafiles follow a "tab-separated values" format. The first two lines are generally like this:
        <pre class="code"># PubFig Dataset v1.2 - filename.txt - http://cave.cs.columbia.edu/old/databases/pubfig/
#    person    imagenum    url    rect    md5sum</pre>
        <p>The first line identifies the name and version of the dataset, the filename, and has a link back to this website. The second line defines the fields in the file, separated by tabs (<span class="code">'\t'</span>). In this example (similar to the <a class="code" href="#dev_urls">dev_urls.txt</a> and <a class="code" href="#eval_urls">eval_urls.txt</a> files), there are 5 fields: <span class="code">person</span>, <span class="code">imagenum</span>, <span class="code">url</span>, <span class="code">rect</span>, and <span class="code">md5sum</span>. The first two are common to many of the datafiles and are the name of the person and an image index number used to refer to a specific image of that individual. Note that image numbers are <b>not</b> necessarily sequential for each person -- there are "holes" in the counting.</p>
        <p>Subsequent lines contain one entry per line, with field values also separated by tabs.</p>
        <h2 id="citation">Citation</h2>
        <h4>The database is made available only for non-commercial use. If you use this dataset, please cite the following paper:</h4>
        <blockquote id="kumar_iccv_09">
    <div class="txtref">
        <b>"Attribute and Simile Classifiers for Face Verification,"</b><br>
        Neeraj Kumar, Alexander C. Berg, Peter N. Belhumeur, and Shree K. Nayar,<br>
        <i>International Conference on Computer Vision (ICCV), 2009.</i><br>
    </div>
    [<a href="http://cave.cs.columbia.edu/old/publications/pdfs/Kumar_ICCV09.pdf">pdf</a>]
    [<a href="https://cave.cs.columbia.edu/projects/categories/project?cid=Visual+Recognition&pid=Attribute+and+Simile+Classifiers+for+Face+Verification" target="_blank">webpage</a>]
</blockquote>

    </div>
    <div id="dev" class="downloaddiv">
        <!--<div class="dlheader">
            <img src="dev.png">
            <h3>Development Set (60 people)</h3>
            <h4>For algorithm development</h4>
        </div>-->
        <h2>Development Set (60 people) - For algorithm development</h2>
        <div class="dlmain">
            <p class="intro">Please use this dataset when developing your algorithm, to avoid overfitting on the evaluation set. You can create any type and number of training pairs from this dataset.</p>
            <ul>
                <li id="dev_people"><a href="https://cave.cs.columbia.edu/old/databases/pubfig/download/dev_people.txt">dev_people.txt</a>: This contains a list of the <b>60 people</b> in the development set. Each data line contains one person's name. There is NO overlap between this list and the people in the evaluation set nor between this set and the people in the <a href="http://vis-www.cs.umass.edu/lfw/">LFW</a> dataset.</li>
                <li id="dev_urls"><a href="https://cave.cs.columbia.edu/old/databases/pubfig/download/dev_urls.txt">dev_urls.txt</a>: This contains URLs for all <b>16,336 images</b> of the 60 people in the development set. (Because of copyright issues, we cannot distribute the images themselves.) Each data line is for one image and contains 5 elements, separated by tabs (<span class="code">'\t'</span>):
                <ul>
                    <li>the person name,</li>
                    <li>the image number for that person,</li>
                    <li>the original image url,</li>
                    <li>the face rectangle around the chosen person, as <span class="code">x0,y0,x1,y1</span> coordinates (x- and y-locations of the top-left and bottom-right corners of the face). Note that we only give the rectangle for the chosen person, even if there are multiple faces in the image.</li>
                    <li>the md5 checksum for the original image, as computed using the linux <span class="code">md5sum</span> utility.</li>
                </ul></li>
            </ul>
        </div>
    </div>
    <div id="eval" class="downloaddiv">
        <!--<div class="dlheader">
            <img src="evaluation.png">
            <h3>Evaluation Set (140 people)</h3>
            <h4>ONLY for final performance evaluation</h4>
        </div>-->
        <h2>Evaluation Set (140 people) - ONLY for final performance evaluation</h2>
        <div class="dlmain">
            <p class="intro">Please use this dataset ONLY when evaluating your algorithm, in preparation for submitting/publishing results. This is to prevent overfitting to the data and obtaining unrealistic results.</p>
            <ul>
                <li id="#eval_people"><a href="https://cave.cs.columbia.edu/old/databases/pubfig/download/eval_people.txt">eval_people.txt</a>: This contains a list of the <b>140 people</b> in the evaluation set. The format is identical to that of the <a href="#dev_people" class="code">dev_people.txt</a> file: Each data line contains one person's name.</li>
                <li id="eval_urls"><a href="https://cave.cs.columbia.edu/old/databases/pubfig/download/eval_urls.txt">eval_urls.txt</a>: This contains URLs for all <b>42,461 images</b> of the 140 people in the evaluation set. (Because of copyright issues, we cannot distribute the images themselves.) The format is identical to that of the <a href="https://cave.cs.columbia.edu/old/databases/pubfig/download/dev_urls" class="code">dev_urls.txt</a> file: Each data line is for one image, and contains 5 elements, separated by tabs (<span class="code">'\t'</span>):
                <ul>
                    <li>the person name,</li>
                    <li>the image number for that person,</li>
                    <li>the original image url,</li>
                    <li>the face rectangle around the chosen person, as <span class="code">x0,y0,x1,y1</span> coordinates (x- and y-locations of the top-left and bottom-right corners of the face). Note that we only give the rectangle for the chosen person, even if there are multiple faces in the image.</li>
                    <li>the md5 checksum for the original image, as computed using the linux <span class="code">md5sum</span> utility.</li>
                </ul></li>
                <li id="pubfig_labels"><a href="https://cave.cs.columbia.edu/old/databases/pubfig/download/pubfig_labels.txt">pubfig_labels.txt</a>: This contains some additional labels for each image in the evaluation set. Each data line contains the following fields, separated by tabs (<span class="code">'\t'</span>):
                <ul>
                    <li>the person name,</li>
                    <li>the image number for that person,</li>
                    <li>pose information as computed by our face detector, given as either <span class="code">frontal</span> (both yaw and pitch within 10 degrees) or <span class="code">non-frontal</span></li>
                    <li>lighting information as labeled by users on <a href="http://www.mturk.com/">Amazon Mechanical Turk</a>, given as either <span class="code">frontal</span> or <span class="code">non-frontal</span></li>
                    <li>expression information as labeled by users on <a href="http://www.mturk.com/">Amazon Mechanical Turk</a>, given as either <span class="code">neutral</span> or <span class="code">non-neutral</span></li>
                </ul></li>
                <li id="pubfig_full.txt"><a href="https://cave.cs.columbia.edu/old/databases/pubfig/download/pubfig_full.txt">pubfig_full.txt</a>: Full verification benchmark of <b>20,000 images</b>, divided into <b>10 cross-validation sets</b>. Each set is mutually disjoint from all other sets, both by <b>person</b> and by <b>image</b>. During evaluation, you should use 9 of the sets for training, and the remaining 1 for testing. Then rotate through all 10 sets, so that in the end you have evaluated all pairs. Since each set is disjoint by identity, your evaluation algorithm will never have seen that person in training. <b>Please do NOT use the filenames or person identities for anything other than reading the images!</b> The format of this file is similar, but not identical, to that of the <a href="http://vis-www.cs.umass.edu/lfw/">LFW</a> benchmark:
                <ul>
                    <li>The 1st line is a comment (starts with <span class="code">'#'</span>) identifying the file.</li>
                    <li>The 2nd line lists the number of cross-validation sets in this file (10 currently). After this follows each cross-validation set.</li>
                    <li>For each cross-validation set, the 1st line contains the number of positive and negative pairs within the set, separated by a tab.</li>
                    <li>This is then followed by the given number of positive examples (pairs of images of the same person), one per line. Each line contains 4 elements separated by tabs, for example:
                    <pre class="code">Jodie Foster    81    Jodie Foster    220</pre>
                    These are:
                    <ul>
                        <li>The first person's name</li>
                        <li>The image number of the first person (as in <a href="https://cave.cs.columbia.edu/old/databases/pubfig/download/eval_urls" class="code">eval_urls.txt</a>)</li>
                        <li>The second person's name (for positive examples, this is the same as the first)</li>
                        <li>The image number of the second person (as in <a href="https://cave.cs.columbia.edu/old/databases/pubfig/download/eval_urls" class="code">eval_urls.txt</a>)</li>
                    </ul></li>
                    <li>Finally, there are the given number of negative examples, in exactly the same format.</li>
                </ul>
            </li></ul>
            </div>
        </div>
    </div>
    <div id="misc" class="downloaddiv">
        <h2>Miscellanous Files</h2>
        <div class="dlmain">
            <p class="intro">These are some datafiles that cover both sets or might be helpful for related tasks.</p>
                <li id="pubfig_attributes"><a href="https://cave.cs.columbia.edu/old/databases/pubfig/download/pubfig_attributes.txt">pubfig_attributes.txt</a>: A list of all <b>73 attribute values</b> for all of the images in PubFig, computed using a newer version of our attribute classifiers. They are in the standard data format. Each data line contains the attribute values for a given image, referenced by person name and image index. Positive attribute values indicate the presence of the attribute, while negative ones indicate its absence or negation. The magnitude of the value indicates the degree to which the attribute is present/negated. The magnitudes are simply the distance of a sample to the support vector for the given classifier (using an RBF kernel). Thus, magnitudes should not be directly compared, even for the same attribute (and certainly not for different attributes). For details, please see the paper cited above.</li>
            <ul>
        </ul></div>
    </div>
    <!-- <h2>Previous Versions</h2>
    Old version of this page and the datafiles are archived in case they are needed: -->
    <!-- <ul>
        <li><a href="https://cave.cs.columbia.edu/old/databases/pubfig/download/1.1/">Version 1.1 (Sep. 10, 2010)</a></li>
        <li><a href="https://cave.cs.columbia.edu/old/databases/pubfig/download/1.0/">Version 1.0 (Dec. 21, 2009)</a></li>
    </ul> -->
</div>

<div id="footer">
    (c) 2010 Columbia University | Contact <a href="mailto:neeraj@cs.columbia.edu">Neeraj Kumar</a> for any questions/problems/bug reports/etc. | Current PubFig version: <b>1.2</b>
</div>
<br>

  </div>
</template>

<script>
export default {

}
</script>

<style>
.container{
    width:70%;
    margin-left:auto;
    margin-right:auto;
    text-align: left;
	font-size: 18px;
}
.table{
        margin:auto;
}
#everything {
    font-family: Arial, sans-serif;
    padding: 10px;
    line-height: 1.3em;
    margin: 0 auto;
    width: 1000px;
    margin-top: 10px;
}

.teaser {
    display: block;
    margin: 0 auto;
}

a {
    color: blue;
    text-decoration: none;
}
#topmatter{
    text-align: left;
}
#header {
    position: relative;
    display: block;
    height: 2.2em;
    /* border-bottom: 1px solid lightgray; */
    margin-bottom: 10px;
}

#header h2 {
    margin-top: 5px;
    display: inline-block;
    margin-left: 15px;
}

#header a {
    color: black;
}

#header a:hover {
    color: blue;
}

a:hover {
    text-decoration: underline;
}

#header .left {
    position: absolute;
    left: 0;
}

#header .left h2 {
    margin-left: 0;
}

#header .right {
    position: absolute;
    right: 0;
}

#header .selected {
    color: #ff872b;
}

h1 {
    font-size: 2.0em;
    margin-bottom: 10px;
}

h1.sectiontitle {
    text-align: left;
    margin: 20px;
}

h2 {
    font-size: 1.2em;
    margin: 20px 0 10px 0;
    text-align:left;
}

p {
    margin-bottom: 1em;
}

li {
    list-style: square outside;
    margin-left: 1em;
    margin-top: 0.5em;
    text-align:left;
    margin-bottom: 0.5em;
}

ol li {
    list-style-type: decimal;
    margin-left: 1.5em;
}

li li {
    list-style: circle outside;
}



div#content {
    clear: both;
    width: 100%;
    background: #f1ebda;
    padding-top: 10px;
    padding-bottom: 3em;
}

table caption {
    font-size: 2em;
}

h3 {
    margin-top: 5px;
    text-align: left;
}


div.resultpane {
    margin: 0 auto;
    margin-bottom: 30px;
}

div.rocdiv {
    margin-top: 2em;
    text-align: left;
}
div.tablediv {
    position: relative;
    text-align: left;
    margin-bottom: 1em;
}
table.resultstable {
    margin: 0 auto;
    border: 1px solid black;
}
table.resultstable th {
    border-bottom: 1px dotted black;
    padding: 5px;
    text-align: left;
}

table.resultstable td {
    padding: 5px;
    text-align: left;
}

.first{
    padding-right: 5px;
    border-right: 1px dotted black;
}

.exploretable {
    margin: 0 auto;
    text-align: left;
}


.exploreperson {
    display: inline-block;
    width: 186px;
    height: 130px;
    margin-bottom: 1em;
    margin-left: 5px;
    margin-right: 5px;
}

.exploreperson img {
    width: 170px;
    height: 85px;
    padding: 2px;
    border: 1px solid lightgray;
}

.exploreperson a:hover img {
    border-color: blue;
}

blockquote {
    margin-left: 2em;
    margin-top: 0.5em;
}

.fakelink {
    cursor: pointer;
}

.bibref {
    margin-top: 10px;
    margin-left: 10px;
    display:none;
    font-family: monospace;
}


.code {
    font-family: monospace;
    font-size: 1.2em;
}

pre {
    text-align: left;
    padding: 5px;
    background: lightgray;
    margin: 5px 0;
}

</style>